import { useState, useRef, useEffect } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import Loader from "../../../components/Loader/Loader";
import { getItemFromSessionStorage } from "../../../utils/utility";
import { Settings } from "@mui/icons-material";
import {
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import SocialOutputSettings from "../Settings";
import FlippingCard from "../fixedMasonry/FlippingCard";
import { URLS } from "../../../constants";
import {
  DEFAULT_SETTINGS_SOCIAL_GRID,
  VARIANT_LOCALSTORAGE_KEYS,
} from "../../../utils/defaultSettings";
import "./GridLayout.css";
import "../fixedMasonry/FixedMasonry.css";
import { uvenuFetcher } from "../../../utils/uvenu-fetcher";
import {
  checkMediaOrientation,
  getOrientation,
} from "../fixedMasonry/CheckOrientation";

const SETTINGS_LOCALSTORAGE_KEY = VARIANT_LOCALSTORAGE_KEYS.gridLayout;
const POLLING_INTERVAL = 6000;
var TOTAL_PAGES = 0;
const MAX_OBJECTS_THRESHOLD = 80;
const REMOVE_COUNT = 10;
const GridLayoutAutoResized = (props) => {
  const getInitialSettings = () => {
    if (props.savedSettings) {
      return props.savedSettings;
    } else {
      return getItemFromSessionStorage(SETTINGS_LOCALSTORAGE_KEY)
        ? getItemFromSessionStorage(SETTINGS_LOCALSTORAGE_KEY)
        : DEFAULT_SETTINGS_SOCIAL_GRID;
    }
  };
  const [settings, setSettings] = useState(getInitialSettings);

  const [mediaArray, setMediaArray] = useState([]);

  const [isLoading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(
    settings.numberOfColumns * settings.numberOfRows * 2
  );
  const [firstLoadArray, setFirstLoadArray] = useState([]);
  const [NumberOfCards, setNumberOfCard] = useState(
    settings.numberOfColumns * settings.numberOfRows
  );
  const [currentFlipIndex, setCurrentFlipIndex] = useState(-1);
  const [flipId, setFlipId] = useState(
    firstLoadArray.length
      ? firstLoadArray[0][0]._id + firstLoadArray[0][1]._id
      : ""
  );
  const [dialogOpen, setDialogOpen] = useState(null);
  let isRelayout = useRef(false);

  useEffect(() => {
    isRelayout.current = true;
  }, []);

  useEffect(() => {
    setNumberOfCard(settings.numberOfColumns * settings.numberOfRows);
    setPerPage(settings.numberOfColumns * settings.numberOfRows * 2);
  }, [settings.numberOfColumns, settings.numberOfRows]);

  useEffect(() => {
    isRelayout.current = true;
    setPage(0);
  }, [NumberOfCards]);

  useEffect(() => {
    fetchContent(page);
  }, [page, perPage]);

  const getPlaceholderCardsArray = (cardsCount) => {
    const placeholders = [];
    for (let i = 0; i < cardsCount; i++) {
      placeholders.push([
        { _id: `placeholder-${i}`, mimeType: "placeholder" },
        { _id: `placeholder-${i}1`, mimeType: "placeholder" },
      ]);
    }
    return placeholders;
  };

  const getFilledCardsArray = (mediaFiles = []) => {
    const arr = [];
    if (mediaFiles.length) {
      for (let side = 0; side < 2; side++) {
        for (let i = 0; i < NumberOfCards; i++) {
          if (side === 0) {
            arr.push([
              mediaFiles.length
                ? mediaFiles.shift()
                : { _id: `placeholder-${side}-${i}`, mimeType: "placeholder" },
              { _id: `placeholder-2-${side}-${i}`, mimeType: "placeholder" },
            ]);
          } else {
            if (arr[i][1]._id.includes("placeholder") && mediaFiles.length) {
              arr[i][1] = mediaFiles.shift();
            }
          }
        }
      }
    }
    if (mediaFiles.length) {
      setMediaArray(mediaFiles);
    }

    let emptyCards = [];
    if (arr.length < NumberOfCards) {
      const diff = NumberOfCards - arr.length;
      emptyCards = getPlaceholderCardsArray(diff);
    }
    return [...arr, ...emptyCards];
  };
  const checkImageOrientationAsync = (imageUrl, mimeType, sharedMediaId) => {
    return new Promise((resolve, reject) => {
      checkMediaOrientation(
        imageUrl,
        mimeType,
        (error, result, errorEvent) => {
          if (error) {
            console.error(error, errorEvent);
            reject(new Error(error));
          } else {
            resolve(result);
          }
        },
        sharedMediaId
      );
    });
  };
  const fetchContent = async (
    pageNumber,
    dontShowLoader = false,
    previousMediaCount = 0
  ) => {
    try {
      if (isRelayout.current === true && !dontShowLoader) {
        setLoading(true);
      }
      const rawResponse = await uvenuFetcher({
        url: URLS.GET_SOCIAL_SCROLL(
          props.eventId,
          pageNumber,
          perPage,
          undefined,
          undefined,
          "desc"
        ),
        method: "GET",
      });
      setLoading(false);
      const response = rawResponse.json;
      const mediaFiles = response?.mediaFiles || [];
      let timeoutId = null;
      if (mediaFiles.length > 0) {
        const totalMediaCount = response.totalCount;
        TOTAL_PAGES = Math.ceil(totalMediaCount / perPage);
        for (const item of mediaFiles) {
          try {
            if (item.metadata && item.metadata.width && item.metadata.height) {
              if (!item.orientation) {
                item.metadata["orientation"] = getOrientation(
                  item.metadata.width,
                  item.metadata.height
                ).orientation;
              }
            } else {
              const result = await checkImageOrientationAsync(
                item.mediaFileUrl,
                item.mimeType,
                item._id
              );
              item.metadata = result;
            }

            const { orientation, width, height } = item.metadata;
            item.orientation = orientation;
            item.width = width;
            item.height = height;
          } catch (error) {
            console.error(
              "Error determining image orientation:",
              error.message
            );
          }
        }
        if (isRelayout.current === true) {
          if (previousMediaCount !== mediaFiles.length) {
            setFirstLoadArray(getFilledCardsArray(mediaFiles));
          }

          if (TOTAL_PAGES > 1) {
            isRelayout.current = false;
            clearTimeout(timeoutId);
          } else {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
              fetchContent(0, true, mediaFiles.length);
            }, POLLING_INTERVAL);
          }
        } else {
          setMediaArray((prevMedia) => [...prevMedia, ...mediaFiles]);
        }
      } else {
        setFirstLoadArray(getPlaceholderCardsArray(NumberOfCards));
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          fetchContent(0, true);
        }, POLLING_INTERVAL);
      }
    } catch (error) {
      console.error("Error fetching content:", error);
    }
  };
  const getRootContainerStyles = () => {
    return {
      height: props.height,
      width: props.width,
      backgroundColor: settings.backgroundColor,
      backgroundImage: settings.backgroundImage
        ? `url(${settings.backgroundImage})`
        : "none",
    };
  };
  const handleSettingsChange = (newSettings) => {
    setSettings(newSettings);
  };
  useEffect(() => {
    if (firstLoadArray.length) {
      const currentCard = firstLoadArray[currentFlipIndex];
      if (currentCard) {
        const id1 = currentCard[0]?._id;
        const id2 = currentCard[1]._id;
        if (!id1?.includes("placeholder") && !id2?.includes("placeholder")) {
          setFlipId(
            firstLoadArray[currentFlipIndex][0]._id +
              firstLoadArray[currentFlipIndex][1]._id
          );
        }
      }
    }
  }, [currentFlipIndex, firstLoadArray]);

  useEffect(() => {
    if (!firstLoadArray.length) {
      return;
    }
    const interval = setInterval(() => {
      setCurrentFlipIndex((pre) => {
        if (pre < NumberOfCards - 1) {
          return pre + 1;
        } else if (pre === NumberOfCards - 1) {
          return 0;
        } else {
          return pre;
        }
      });
    }, settings.flipDelay * 1000);
    return () => {
      clearInterval(interval);
    };
  }, [firstLoadArray, settings.flipDelay, NumberOfCards]);
  const renderSettings = () => {
    return (
      <>
        <IconButton
          className="settings-icon"
          onClick={() => setDialogOpen(true)}
          size="large"
        >
          <Settings />
        </IconButton>
        <Dialog open={dialogOpen || false} onClose={() => setDialogOpen(false)}>
          <DialogTitle>Settings</DialogTitle>
          <DialogContent className="dialog-content">
            <SocialOutputSettings
              onChange={handleSettingsChange}
              localStorageUniqueKey={SETTINGS_LOCALSTORAGE_KEY}
              defaultSettings={settings}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };
  useEffect(() => {
    if (mediaArray.length < NumberOfCards * 2) {
      if (TOTAL_PAGES) {
        setPage((prePage) => {
          if (prePage < TOTAL_PAGES - 1) {
            return prePage + 1;
          } else {
            return 0;
          }
        });
      }
    } else if (mediaArray.length >= MAX_OBJECTS_THRESHOLD) {
      if (mediaArray.length > REMOVE_COUNT) {
        setMediaArray((pre) => pre.slice(REMOVE_COUNT));
      }
    }
  }, [mediaArray, NumberOfCards]);
  const onRequestNewMeida = (cb, orientation) => {
    if (mediaArray.length) {
      const copy = [...mediaArray];
      cb(copy.shift());
      setMediaArray(copy);
    }
  };
  const getLayoutStyles = () => {
    if (settings.selectedGridLayoutOption === 1) {
      // Row Layout
      return {
        width: `calc(100% - ${settings.gap}px)`,
        gridTemplateColumns: `repeat(${settings.numberOfColumns}, 1fr)`,
        columnGap: `${settings.gap}px`,
        rowGap: `${settings.gap}px`,
        padding: `${settings.gap}px`,
      };
    } else if (settings.selectedGridLayoutOption === 2) {
      // Column Layout
      return {
        height: `calc(100% - ${settings.gap * 2}px)`,
        width: `${
          (props.height / settings.numberOfRows - settings.gap * 2) *
            (9 / 16) *
            settings.numberOfColumns +
          settings.gap * 2 * (settings.numberOfColumns - 1)
        }px`,
        gridTemplateRows: `repeat(${settings.numberOfRows}, 1fr)`,
        gridTemplateColumns: `repeat(${settings.numberOfColumns}, auto)`,
        columnGap: `${settings.gap}px`,
        rowGap: `${settings.gap}px`,
        padding: `${settings.gap}px`,
      };
    } else if (settings.selectedGridLayoutOption === 169) {
      // 16 : 9
      return {
        aspectRatio: 2.4,
        width: props.height * 2.4 - settings.gap * 2 * 2.4,
        gridTemplateColumns: `repeat(${settings.numberOfColumns}, 1fr)`,
        columnGap: `${settings.gap}px`,
        rowGap: `${settings.gap}px`,
        padding: `${settings.gap}px`,
      };
    }
  };

  useEffect(() => {
    props.onSettingsChange(settings);
  }, [settings]);
  useEffect(() => {
    if (props.savedSettings) {
      isRelayout.current = true;
      setSettings(props.savedSettings);
    }
    return () => {
      isRelayout.current = false;
    };
  }, [props.savedSettings]);

  return (
    <div className="root-container-grid" style={getRootContainerStyles()}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {renderSettings()}
          <div style={getLayoutStyles()} className="uv-social-grid">
            {firstLoadArray.map((s, i) => {
              return i < NumberOfCards && s && s[0] && s[1] ? (
                <FlippingCard
                  settings={settings}
                  m1={s[0]}
                  m2={s[1]}
                  key={s[0]._id + s[1]._id}
                  flipId={flipId}
                  onRequestNewMeida={onRequestNewMeida}
                  orientation={s[0].orientation}
                  className={`card-grid-${settings.cardOrientation}${
                    settings.selectedGridLayoutOption &&
                    settings.selectedGridLayoutOption === 2
                      ? " social-grid-vertical-card"
                      : ""
                  }`}
                />
              ) : null;
            })}
          </div>
        </>
      )}
    </div>
  );
};
const GridLayout = (props) => {
  return (
    <>
      <div style={{ position: "fixed", inset: 0 }}>
        <AutoSizer>
          {({ width, height }) => {
            return (
              <GridLayoutAutoResized
                width={width}
                height={height}
                {...props}
              ></GridLayoutAutoResized>
            );
          }}
        </AutoSizer>
      </div>
    </>
  );
};
export default GridLayout;
