import { motion, useAnimationControls } from "framer-motion";
import { useEffect } from "react";
import "./Card.css";
import { FormatQuote } from "@mui/icons-material";

const Card = ({
  i,
  data,
  itemsLength,
  mediaItemRotateBy,
  mediaItemX,
  mediaCardScale,
  showQuoteIcon,
  quoteFontSize,
  enableBlur,
  shuffle,
  slideDelay,
  font,
}) => {
  const CARD_GAP = 12;
  const ANIMATION_DURATION = 2;

  const controls = useAnimationControls();
  const controlsCard = useAnimationControls();

  useEffect(() => {
    controls.set({ zIndex: i });
    if (itemsLength && controlsCard && controls) {
      const moveCardDown = async (index) => {
        await controlsCard.start((ind) => {
          if (ind === index) {
            return {
              y: ["0%", "200%"],
              opacity: [1, 0],
              transition: {
                duration: ANIMATION_DURATION,
                delay: slideDelay,
              },
            };
          }
          return {
            y: [0, 0],
            opacity: [1, 1],
          };
        });
        await controls.start((ind) => {
          if (ind === index) {
            return {
              zIndex: 0,
              transition: { duration: ANIMATION_DURATION },
            };
          }
          return {
            zIndex: ind + 1,
            transition: { duration: ANIMATION_DURATION },
          };
        });
        await controlsCard.start((ind) => {
          return {
            y: ["200%", "0%"],
            transition: { duration: ANIMATION_DURATION / 2 },
          };
        });
        await controlsCard.start((ind) => {
          return {
            opacity: [0, 1],
            transition: { duration: 0 },
          };
        });
        shuffle();
      };

      if (i === itemsLength - 1) {
        moveCardDown(i);
      } else {
        controlsCard.start((i) => {
          return {
            top: `${i * CARD_GAP}px`,
            transition: { duration: 1, delay: slideDelay },
          };
        });
      }
    }
  }, [itemsLength, i, controls, controlsCard, slideDelay]);

  return (
    <motion.div
      className="cardContainer"
      data-index={i}
      style={{
        zIndex: i,
        marginTop: `-${((itemsLength - 1) * CARD_GAP) / 2}px`,
        fontFamily: font,
      }}
      animate={controls}
      custom={i}
    >
      <motion.div
        animate={controlsCard}
        custom={i}
        style={{
          backgroundColor: data.cardSettings.color,
          backgroundImage: data.cardSettings.bgImage
            ? `url(${data.cardSettings.bgImage})`
            : "none",

          color: data.cardSettings.contentColor,
        }}
        initial={{
          top: `${i * CARD_GAP}px`,
        }}
        className="card"
      >
        <div className="cardBody">
          <div
            className="imageContainer"
            style={{
              transform: `rotate(${mediaItemRotateBy}deg)`,
              left: `${mediaItemX}px`,
              scale: `${Number(mediaCardScale) / 100}`,
              border: `3px solid ${data.cardSettings.contentColor}`,
            }}
          >
            <motion.div className="inner" style={{ overflow: "hidden" }}>
              {data.mediaFileUrl ? (
                data.mimeType.includes("video") ? (
                  <>
                    <video
                      className="content-video uv-main-media-tag"
                      autoPlay
                      playsInline
                      loop
                      controls={false}
                      muted
                      src={data.mediaFileUrl}
                      disableRemotePlayback={true}
                    />
                    {enableBlur ? (
                      <video
                        className="blur-bg-media"
                        disableRemotePlayback={true}
                        src={data?.mediaFileUrl}
                        playsInline=""
                        loop=""
                      ></video>
                    ) : null}
                  </>
                ) : (
                  <>
                    <img
                      src={data.mediaFileUrl}
                      alt="Uploaded"
                      className="uv-main-media-tag"
                    />
                    {enableBlur ? (
                      <img
                        className="blur-bg-media"
                        src={data?.mediaFileUrl}
                        alt="Social quote blur background"
                      />
                    ) : null}
                  </>
                )
              ) : (
                <div className="mediacard-placeholder skeleton"></div>
              )}
            </motion.div>
          </div>
          <div className="textContainer">
            <h2 style={{ fontSize: `${(Number(quoteFontSize) + 2) / 10}vw` }}>
              {data.userId
                ?.split(" ")
                ?.map(
                  (str) =>
                    str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
                )
                ?.join(" ")}
            </h2>
            <div
              className="description"
              style={{ fontSize: `${Number(quoteFontSize / 10)}vw` }}
            >
              <p>{data.comment}</p>
            </div>
          </div>
          {showQuoteIcon ? (
            <div
              className="quote-icon-container"
              style={{ color: data.cardSettings.color }}
            >
              <FormatQuote
                sx={{ color: data.cardSettings.contentColor, fontSize: "60px" }}
              />
            </div>
          ) : null}
        </div>
      </motion.div>
    </motion.div>
  );
};

export default Card;
