import React, { useEffect, useState } from "react";
import { URLS } from "../../../constants";
import { uvenuFetcher } from "../../../utils/uvenu-fetcher";
import { checkMediaOrientation, getOrientation } from "./CheckOrientation";
import { Settings } from "@mui/icons-material";
import {
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import "./FixedMasonry.css";
import FlippingCard from "./FlippingCard";
import AutoSizer from "react-virtualized-auto-sizer";
import Loader from "../../../components/Loader/Loader";
import SocialOutputSettings from "../Settings";
import {
  DEFAULT_SETTINGS_FIXED_MASONRY,
  VARIANT_LOCALSTORAGE_KEYS,
} from "../../../utils/defaultSettings";
import {
  getItemFromSessionStorage,
  isLandscapeCardOfMasonry,
  mergeAndDeduplicate,
} from "../../../utils/utility";
import { useRef } from "react";

const MAX_OBJECTS_THRESHOLD = 80;
const REMOVE_COUNT = 10;
const POLLING_INTERVAL = 6000;
const SETTINGS_LOCALSTORAGE_KEY = VARIANT_LOCALSTORAGE_KEYS.fixedMasonry;
var totalPages = 0;

const AutoResizedSocialDynamicMasonry = (props) => {
  const getInitialSettings = () => {
    if (props.savedSettings) {
      return props.savedSettings;
    } else {
      return getItemFromSessionStorage(SETTINGS_LOCALSTORAGE_KEY)
        ? getItemFromSessionStorage(SETTINGS_LOCALSTORAGE_KEY)
        : DEFAULT_SETTINGS_FIXED_MASONRY;
    }
  };
  const [settings, setSettings] = useState(getInitialSettings);

  const [landscapeMedia, setLandscapeMedia] = useState([]);
  const [portraitMedia, setPortraitMedia] = useState([]);

  const [page, setPage] = useState(null);
  //const perPage = 20;
  const [mediaIds, setMediaIds] = useState(new Set());
  const [dialogOpen, setDialogOpen] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [forceFetch, setForceFetch] = useState(false);
  const [NumberOfCards, setNumberOfCard] = useState(settings.selectedLayout);
  let isRelayout = useRef(false);
  let timeoutId1 = null;
  let timeoutId2 = null;
  useEffect(() => {
    props.onSettingsChange(settings);
  }, [settings]);
  useEffect(() => {
    setForceFetch(true);
    setNumberOfCard(settings.selectedLayout);
  }, [settings.selectedLayout]);
  useEffect(() => {
    if (props.savedSettings) {
      isRelayout.current = true;
      setSettings(props.savedSettings);
    }
    return () => {
      isRelayout.current = false;
    };
  }, [props.savedSettings]);
  useEffect(() => {
    isRelayout.current = true;
    setPage((pre) => {
      if (pre === 0 && forceFetch) {
        setForceFetch(false);
        fetchContent(page);
      }
      return 0;
    });
    return () => {
      clearTimeout(timeoutId1);
      clearTimeout(timeoutId2);
    };
  }, [NumberOfCards]);
  useEffect(() => {
    isRelayout.current = true;
    return () => {
      clearTimeout(timeoutId1);
      clearTimeout(timeoutId2);
    };
  }, []);
  useEffect(() => {
    fetchContent(page);

    return () => {
      clearTimeout(timeoutId1);
      clearTimeout(timeoutId2);
    };
  }, [page]);

  const [firstLoadArray, setFirstLoadArray] = useState([]);

  const eventId = window.location.hash.replace("#", "") || props.eventId;

  const checkImageOrientationAsync = (imageUrl, mimeType, sharedMediaId) => {
    return new Promise((resolve, reject) => {
      checkMediaOrientation(
        imageUrl,
        mimeType,
        (error, result, errorEvent) => {
          if (error) {
            console.error(error, errorEvent);
            reject(new Error(error));
          } else {
            resolve(result);
          }
        },
        sharedMediaId
      );
    });
  };

  const fetchContent = async (
    pageNumber,
    dontShowLoader = false,
    previousMediaCount = 0
  ) => {
    if (pageNumber === null) {
      return;
    }
    try {
      if (isRelayout.current === true && !dontShowLoader) {
        setLoading(true);
      }
      const perPage = NumberOfCards ? NumberOfCards * 2 : 20;
      const rawResponse = await uvenuFetcher({
        url: URLS.GET_SOCIAL_SCROLL(
          eventId,
          pageNumber,
          perPage,
          undefined,
          undefined,
          "desc"
        ),
        method: "GET",
      });
      setLoading(false);
      const response = rawResponse.json;
      const mediaFiles = response?.mediaFiles || [];

      if (mediaFiles.length > 0) {
        const totalMediaCount = response.totalCount;
        totalPages = Math.ceil(totalMediaCount / perPage);
        const classifiedMedia = { landscape: [], portrait: [] };
        const newMediaIds = new Set(mediaIds);
        for (const item of mediaFiles) {
          try {
            if (item.metadata && item.metadata.width && item.metadata.height) {
              if (!item.orientation) {
                item.metadata["orientation"] = getOrientation(
                  item.metadata.width,
                  item.metadata.height
                ).orientation;
              }
            } else {
              const result = await checkImageOrientationAsync(
                item.mediaFileUrl,
                item.mimeType,
                item._id
              );
              item.metadata = result;
            }

            const { orientation, width, height } = item.metadata;
            item.orientation = orientation;
            item.width = width;
            item.height = height;
            classifiedMedia[orientation].push(item);
            newMediaIds.add(item._id);
          } catch (error) {
            console.error(
              "Error determining image orientation:",
              error.message
            );
          }
        }
        setMediaIds(newMediaIds);
        if (isRelayout.current === true) {
          if (previousMediaCount !== mediaFiles.length) {
            setFirstLoadArray(
              getFirstLoadOrientationPatternedArray(classifiedMedia)
            );
          }

          if (totalPages > 1) {
            isRelayout.current = false;
            clearTimeout(timeoutId1);
          } else {
            clearTimeout(timeoutId1);
            timeoutId1 = setTimeout(() => {
              fetchContent(0, true, mediaFiles.length);
            }, POLLING_INTERVAL);
          }
        } else {
          setLandscapeMedia((prevMedia) =>
            mergeAndDeduplicate([...prevMedia], [...classifiedMedia.landscape])
          );

          setPortraitMedia((prevMedia) =>
            mergeAndDeduplicate([...prevMedia], [...classifiedMedia.portrait])
          );
        }
      } else {
        setFirstLoadArray(getPlaceholderCardsArray(NumberOfCards));
        clearTimeout(timeoutId2);
        timeoutId2 = setTimeout(() => {
          fetchContent(0, true);
        }, POLLING_INTERVAL);
      }
    } catch (error) {
      console.error("Error fetching content:", error);
    }
  };

  useEffect(() => {
    if (landscapeMedia.length + portraitMedia.length < NumberOfCards * 2) {
      if (totalPages) {
        setPage((prePage) => {
          if (prePage < totalPages - 1) {
            return prePage + 1;
          } else {
            return 0;
          }
        });
      }
    } else if (
      landscapeMedia.length + portraitMedia.length >=
      MAX_OBJECTS_THRESHOLD
    ) {
      if (landscapeMedia.length > REMOVE_COUNT) {
        setLandscapeMedia((pre) => pre.slice(REMOVE_COUNT));
      } else if (portraitMedia.length > REMOVE_COUNT) {
        setPortraitMedia((pre) => pre.slice(REMOVE_COUNT));
      }
    }
  }, [landscapeMedia, portraitMedia, NumberOfCards]);

  const onRequestNewMeida = (cb, orientation) => {
    if (orientation === "portrait") {
      if (portraitMedia.length) {
        const copy = [...portraitMedia];
        cb(copy.shift());
        setPortraitMedia(copy);
      } else {
        const copy = [...landscapeMedia];
        cb(copy.shift());
        setLandscapeMedia(copy);
      }
    } else if (orientation === "landscape") {
      if (landscapeMedia.length) {
        const copy = [...landscapeMedia];
        cb(copy.shift());
        setLandscapeMedia(copy);
      } else {
        const copy = [...portraitMedia];
        cb(copy.shift());
        setPortraitMedia(copy);
      }
    }
  };

  const handleSettingsChange = (newSettings) => {
    setSettings(newSettings);
  };

  const renderSettings = () => {
    return (
      <>
        <IconButton
          className="settings-icon"
          onClick={() => setDialogOpen(true)}
          size="large"
        >
          <Settings />
        </IconButton>
        <Dialog open={dialogOpen || false} onClose={() => setDialogOpen(false)}>
          <DialogTitle>Settings</DialogTitle>
          <DialogContent className="dialog-content">
            <SocialOutputSettings
              onChange={handleSettingsChange}
              localStorageUniqueKey={SETTINGS_LOCALSTORAGE_KEY}
              defaultSettings={settings}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const [currentFlipIndex, setCurrentFlipIndex] = useState(-1);
  const [flipId, setFlipId] = useState(
    firstLoadArray.length
      ? firstLoadArray[0][0]._id + firstLoadArray[0][1]._id
      : ""
  );

  const getPlaceholderCardsArray = (cardsCount) => {
    const placeholders = [];
    for (let i = 0; i < cardsCount; i++) {
      placeholders.push([
        { _id: `placeholder-${i}`, mimeType: "placeholder" },
        { _id: `placeholder-${i}1`, mimeType: "placeholder" },
      ]);
    }
    return placeholders;
  };

  const getFirstLoadOrientationPatternedArray = ({
    landscape = [],
    portrait = [],
  }) => {
    const arr = [];
    if (portrait.length + landscape.length > 0) {
      for (let side = 0; side < 2; side++) {
        for (let i = 0; i < NumberOfCards; i++) {
          let inventory = portrait;
          if (
            isLandscapeCardOfMasonry(i, settings.selectedLayout) &&
            landscape.length
          ) {
            inventory = landscape;
          }
          if (inventory[0]) {
            if (side === 0) {
              arr.push([
                inventory.shift(),
                { _id: `placeholder-${side}-${i}`, mimeType: "placeholder" },
              ]);
            } else {
              if (arr[i][1]._id.includes("placeholder")) {
                arr[i][1] = inventory.shift();
              }
            }
          }
        }
      }
    }
    if (landscape.length) {
      setLandscapeMedia((prevMedia) =>
        mergeAndDeduplicate([...prevMedia], [...landscape])
      );
    }
    if (portrait.length) {
      setPortraitMedia((prevMedia) =>
        mergeAndDeduplicate([...prevMedia], [...portrait])
      );
    }
    let emptyCards = [];
    if (arr.length < NumberOfCards) {
      const diff = NumberOfCards - arr.length;
      emptyCards = getPlaceholderCardsArray(diff);
    }
    return [...arr, ...emptyCards];
  };

  useEffect(() => {
    if (
      firstLoadArray.length &&
      firstLoadArray[currentFlipIndex] &&
      firstLoadArray[currentFlipIndex]
    ) {
      setFlipId(
        firstLoadArray[currentFlipIndex][0]._id +
          firstLoadArray[currentFlipIndex][1]._id
      );
    }
  }, [currentFlipIndex, firstLoadArray]);

  useEffect(() => {
    if (!firstLoadArray.length) {
      return;
    }
    const interval = setInterval(() => {
      setCurrentFlipIndex((pre) => {
        if (pre < NumberOfCards - 1) {
          return pre + 1;
        } else if (pre === NumberOfCards - 1) {
          return 0;
        } else {
          return pre;
        }
      });
    }, settings.flipDelay * 1000);
    return () => {
      clearInterval(interval);
    };
  }, [firstLoadArray, settings.flipDelay, NumberOfCards]);

  const oneHalf = [];
  const twoHalf = [];
  for (let i = 0; i < firstLoadArray.length / 2; i++) {
    oneHalf.push(i % 2 === 0 ? "landscape" : "portrait");
  }
  for (let i = firstLoadArray.length / 2; i < firstLoadArray.length; i++) {
    twoHalf.push(i % 2 === 0 ? "portrait" : "landscape");
  }
  const getLayoutStyles = () => {
    if (settings.selectedLayout === 4) {
      return {
        aspectRatio: 4 / 3,
        width: props.height * (4 / 3) - settings.gap * 8 * (4 / 3),
        gridTemplateColumns: "1.263fr 1.263fr 2.127fr",
        columnGap: `${settings.gap}px`,
        rowGap: `${settings.gap}px`,
        padding: `${settings.gap}px`,
      };
    } else if (settings.selectedLayout === 7) {
      return {
        aspectRatio: 2.4,
        width: props.height * 2.4 - settings.gap * 2 * 2.4,
        gridTemplateColumns: "2.127fr 1.04fr 1.263fr 1.263fr 2.127fr",
        columnGap: `${settings.gap}px`,
        rowGap: `${settings.gap}px`,
        padding: `${settings.gap}px`,
      };
    } else if (settings.selectedLayout === 10) {
      return {
        width: props.height * 2.4 - settings.gap * 2 * 2.4,
        gridTemplateColumns:
          "1.263fr 1.263fr 2.127fr 1.04fr 1.263fr 1.263fr 2.127fr",
        columnGap: `${settings.gap}px`,
        rowGap: `${settings.gap}px`,
        padding: `${settings.gap}px`,
      };
    }
  };

  const getRootContainerStyles = () => {
    return {
      height: props.height,
      width: props.width,
      backgroundColor: settings.backgroundColor,
      backgroundImage: settings.backgroundImage
        ? `url(${settings.backgroundImage})`
        : "none",
    };
  };
  return (
    <div className="root-container" style={getRootContainerStyles()}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {renderSettings()}
          <div style={getLayoutStyles()} className="uv-fixed-masonry-grid">
            {firstLoadArray.length ? (
              firstLoadArray.map((s, i) => {
                return i < NumberOfCards && s && s[0] && s[1] ? (
                  <FlippingCard
                    settings={settings}
                    m1={s[0]}
                    m2={s[1]}
                    key={s[0]._id + s[1]._id}
                    flipId={flipId}
                    onRequestNewMeida={onRequestNewMeida}
                    orientation={s[0].orientation}
                    className={`card-${settings.selectedLayout}-${i}`}
                  />
                ) : null;
              })
            ) : (
              <Loader />
            )}
          </div>
        </>
      )}
    </div>
  );
};
const SocialDynamicMasonry = (props) => {
  return (
    <>
      <div style={{ position: "fixed", inset: 0 }}>
        <AutoSizer>
          {({ width, height }) => {
            return (
              <AutoResizedSocialDynamicMasonry
                width={width}
                height={height}
                {...props}
              ></AutoResizedSocialDynamicMasonry>
            );
          }}
        </AutoSizer>
      </div>
    </>
  );
};
export default SocialDynamicMasonry;
